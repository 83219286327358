<template>
  <div>
    <div class="notice-list-wrapper" v-if="currentView === 'list'">
      <span class="notice-list-header">공지사항</span>

      <div class="notice-list-header-sub">
        <div class="notice-list-header-number">번호</div>
        <div class="notice-list-header-title">제목</div>
        <div class="notice-list-header-date">작성일</div>
      </div>

      <div class="notice-list">
        <div v-for="(notice, index) in noticeList" :key="index" class="notice-list-item">
          <div class="notice-list-item-number">{{ notice.notice_id }}</div>
          <div class="notice-list-item-title" @click="selectNotice(index)">
            {{ notice.title }}
          </div>
          <div class="notice-list-item-date">{{ formatDate(notice.created) }}</div>
        </div>
      </div>

      <AppPagination v-model="page" :count="pageCount" @input="fetchNoticeList"></AppPagination>
    </div>
    <NoticeView
      v-if="currentView === 'view'"
      :currentIndex="currentIndex"
      :page="page"
      :pageCount="pageCount"
      :limit="limit"
      @close="changeView('list')"
    ></NoticeView>
  </div>
</template>
<script>
import NoticeView from "./NoticeView.vue";
import dayjs from "dayjs";
export default {
  name: "NoticeList",
  components: {
    NoticeView,
  },
  props: {
    limit: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      noticeList: [],
      currentIndex: 0,
      page: 1,
      pageCount: 0,
      currentView: "list",
    };
  },
  mounted() {
    this.fetchNoticeList();
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD");
    },
    selectNotice(index) {
      this.currentIndex = index;
      this.currentView = "view";
      this.$router.push({
        name: "NoticeView",
        query: {
          currentIndex: this.currentIndex,
          page: this.page,
          pageCount: this.pageCount,
          limit: this.limit,
        },
      });
    },
    async fetchNoticeList() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/notice/notice_list`,
          {
            params: {
              used: 1,
              page: this.page,
              pageSize: this.limit,
            },
          }
        );
        this.noticeList = data.items.map((item) => ({
          notice_id: item.notice_id,
          title: item.title,
          content: item.content,
          created: item.created,
          updated: item.updated,
        }));
        this.pageCount = data.totalPages;
      } catch (err) {
        console.error(err);
      }
    },
    changeView(view) {
      this.currentView = view;
      this.$router.push({
        name: "NoticeList",
        query: {
          page: this.page,
          pageSize: this.limit,
        },
      });
    },
  },
};
</script>
<style scoped>
.notice-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 100px;
}

.notice-list-header {
  font-size: 2rem;
  font-weight: 600;
  color: #212121;
  line-height: 44px;
  letter-spacing: 0.25px;
  margin-bottom: 20px;
  width: 100%;
}

.notice-list-header-sub,
.notice-list-item {
  display: grid;
  grid-template-columns: 93px 689px 118px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.notice-list-header-sub {
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #1a237e;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 16px;
  height: 48px;
  box-shadow: 0px -1px 0px 0px #0000001f inset;
}

.notice-list {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.notice-list-item:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.notice-list-item {
  height: 48px;
}

.notice-list-item-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 20px;
  padding-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
