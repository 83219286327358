import axios from "@/plugins/axios";

export default {
  state: {
    status: "",
    isRefreshing: false,
    isVerifyingPassword: false,
    expertId: null,
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state) {
      state.status = "success";
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.isRefreshing = false;
      state.isVerifyingPassword = false;
    },
    refresh_request(state, isRefreshing) {
      state.isRefreshing = isRefreshing;
    },
    verify_request(state, isVerifyingPassword) {
      state.isVerifyingPassword = isVerifyingPassword;
    },
    incrementRefreshAttempts(state) {
      state.refreshAttempts = (state.refreshAttempts || 0) + 1;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post(`${process.env.VUE_APP_API_URL}/auth/login`, user, { withCredentials: true })
          .then((resp) => {
            commit("auth_success");
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/auth/logout`, {}, { withCredentials: true })
          .then(() => {
            commit("logout");
            resolve();
          });
      });
    },
    async refreshToken({ commit, state }) {
      if (state.isRefreshing || state.isVerifyingPassword) {
        throw new Error("Operation in progress");
      }

      commit("refresh_request", true);
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/refresh`,
          {},
          { withCredentials: true }
        );
        commit("auth_success");
        return response;
      } catch (error) {
        commit("logout");
        throw error;
      } finally {
        commit("refresh_request", false);
      }
    },
    async validateToken({ commit, dispatch, state }) {
      if (state.isRefreshing || state.isVerifyingPassword) {
        throw new Error("Operation in progress");
      }
      commit("auth_request");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/protected`,
          {},
          { withCredentials: true }
        );
        commit("auth_success");
        return response;
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.message === "TOKEN_EXPIRED" &&
          !state.isRefreshing &&
          !state.isVerifyingPassword
        ) {
          return dispatch("refreshToken");
        } else {
          commit("auth_error");
          throw error;
        }
      }
    },
    async verifyPassword({ commit }, payload) {
      commit("verify_request", true);
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/users/compare_pw`,
          {
            user_id: payload.user_id,
            password: payload.password,
          },
          { withCredentials: true }
        );
        if (response.status === 200 && response.data.isValid) {
          commit("verify_request", false);
          return true;
        } else {
          commit("verify_request", false);
          return false;
        }
      } catch (error) {
        commit("verify_request", false);
        return false;
      } finally {
        commit("verify_request", false);
      }
    },
  },
  getters: {
    isLoggedIn: (state) => state.status === "success",
    authStatus: (state) => state.status,
  },
};
