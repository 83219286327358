<template>
  <transition name="modal">
    <div class="modal-mask" v-if="visible" @click.self="close">
      <div class="modal-wrapper" @click.stop>
        <div class="modal-container">
          <button class="modal-close-button" @click="close">&times;</button>
          <div class="modal-header" v-if="title">
            <slot name="header">
              <span>{{ title }}</span>
            </slot>
          </div>
          <div class="modal-body" ref="bodyContainer">
            <component :is="body.component" v-bind="body.props"></component>
          </div>
          <div class="modal-footer" v-if="buttons.length > 0">
            <slot name="footer">
              <template v-for="(button, index) in buttons">
                <button
                  :key="index"
                  :class="['modal-button', `modal-button-${button.color}`]"
                  @click="emitAction(button.action)"
                >
                  {{ button.text }}
                </button>
              </template>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: Object,
      default: () => ({
        component: "div", // 기본 컴포넌트
        props: {}, // 기본 props
      }),
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    // [
    //   { text: 'Close', color: 'gray', action: 'close' },
    //   { text: 'Confirm', color: 'blue', action: 'confirm' }
    // ]

    return {
      visible: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    emitAction(action) {
      this.$emit(action);
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  background-color: #fff;
  border-radius: 4px;
}

.modal-container {
  position: relative;
  min-width: 300px;
  border-radius: 4px;
}

.modal-header {
  padding: 16px;
  background: #eceff1;
  min-height: 72px;
  gap: 8px;
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  color: #000000de;
  border-radius: 4px;
}

.modal-close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 0.5em;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #e9e9e9;
  text-align: right;
}

.modal-button-gray {
  background-color: #ccc;
}

.modal-button-blue {
  background-color: #007bff;
}
</style>
