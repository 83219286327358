import Vue from "vue";
import ModalComponent from "../components/Modal.vue";

const ModalClass = Vue.extend(ModalComponent);
const modalInstance = new ModalClass();

modalInstance.$mount(document.createElement("div"));
document.body.appendChild(modalInstance.$el);

const modalService = {
  async open(props) {
    Object.assign(modalInstance, props);
    modalInstance.open();
  },
  close() {
    modalInstance.close();
  },
};

export default modalService;
