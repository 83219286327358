var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.processedExpertData.length > 0)?_c('div',{staticClass:"best",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"best__wrapper"},[_c('div',{staticClass:"best-main-container"},_vm._l((_vm.processedExpertData),function(best){return _c('div',{key:best.expert_id,staticClass:"best-content-wrapper"},[_c('div',{staticClass:"best__header"},[_c('div',{staticClass:"best__header__title"},[_c('span',[_vm._v(_vm._s(best.special_status === 0 ? "전체회원 1위" : "전문가 홈 방문 1위"))])])]),_c('div',{staticClass:"best-content"},[_c('div',{staticClass:"best-row1"},[_c('div',{staticClass:"best-profile"},[_c('div',{staticClass:"best-profile-image-container",on:{"click":function($event){return _vm.navigateToExpert(best.expert_id)}}},[_c('div',{staticClass:"best-triangle-background"}),_c('div',{class:['best-profile-image', { 'image-error': best.imageError }]},[_c('img',{attrs:{"src":best.profile_img2,"alt":"Profile Image"},on:{"error":function($event){return _vm.handleImageError(best.expert_id)}}})]),(_vm.isBroadcastLive(best))?_c('div',{staticClass:"best-live-badge-custom"},[_c('div',{staticClass:"best-live-custom"},[_c('v-icon',[_vm._v("mdi-circle")]),_c('span',[_vm._v("LIVE")])],1)]):_vm._e()])])]),_c('div',{staticClass:"best-row2"},[_c('div',{staticClass:"best-expert-des-container"},[_c('div',{staticClass:"best-expert-name"},[_c('span',[_vm._v(_vm._s(best.expert_nickname))])]),_c('div',{staticClass:"best-expert-home-btn",on:{"click":function($event){return _vm.navigateToExpert(best.expert_id)}}},[_c('img',{staticClass:"img",attrs:{"alt":`expert_home_svg`,"src":_vm.homeSvg}})])]),_c('div',{staticClass:"best-title"},[_c('span',[_vm._v(_vm._s(best.title))])]),_c('div',{staticClass:"best-date",class:{
                'best-paid-date': best.type === 0,
                'best-free-date': best.type === 1,
              }},[_c('span',[_vm._v(_vm._s(_vm.formatDate(best.start_date, best.end_date)))])]),_c('div',{staticClass:"expert-kakao-kakao-talk-btn"},[_c('button',{on:{"click":function($event){return _vm.openKakaoTalk(best.kakao_talk)}}},[_vm._v("무료 카톡방")])]),_c('div',{staticClass:"best-btn",class:{
                'best-paid-broadcast': best.type === 0,
                'best-free-broadcast': best.type === 1,
              },on:{"click":function($event){return _vm.joinBroadcast(best, best.expert_id)}}},[_c('span',[_vm._v(_vm._s(best.type === 0 ? "유료 방송보기" : "무료 방송보기"))])])])])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }