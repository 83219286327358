import Vue from "vue";
import axios from "axios";
import store from "@/store";

const httpClient = axios.create({
  withCredentials: true,
});

httpClient.interceptors.request.use((config) => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("XSRF-TOKEN="))
    ?.split("=")[1];
  if (token) {
    config.headers["X-XSRF-TOKEN"] = token;
  } else {
    console.warn("XSRF-TOKEN not found, proceeding without it.");
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.config && error.response) {
      switch (error.response.status) {
        case 401: {
          const message = error.response.data.message;
          if (message === "TOKEN_EXPIRED" || message === "INVALID_TOKEN_FORMAT") {
            try {
              await store.dispatch("refreshToken");
              return httpClient.request(error.config);
            } catch (refreshError) {
              await store.dispatch("logout");
              return Promise.reject(refreshError);
            }
          } else if (message === "INVALID_TOKEN" || message === "AUTHENTICATION_FAILED") {
            await store.dispatch("logout");
            return Promise.reject(error);
          }
          break;
        }
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = httpClient;

export default httpClient;
