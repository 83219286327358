<template>
  <div class="main-review-container" :class="{ mobile: isMobile }">
    <div class="main-review-wrapper1">
      <img src="@/assets/img/review_background.jpeg" class="main-review-background" />
      <div class="main-review-wrapper">
        <div class="main-review-header-text-container">
          <span class="main-review-header">이용후기</span>
          <span class="main-review-sub-header">실제 퓨처온 회원들이 남긴 후기를 읽어보세요!</span>
        </div>
        <div class="main-review-card-container">
          <div class="main-review-card" v-for="(review, index) in computedReviews" :key="index">
            <div class="main-review-card-content">
              <div class="main-review-card-text">
                <svg
                  class="main-review-card-icon"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.409973 15.12V10.15C0.409973 8.23666 0.713307 6.46333 1.31997 4.83C1.97331 3.19667 3.06997 1.58667 4.60997 0L7.33997 2.03C6.12664 3.38333 5.28664 4.62 4.81997 5.74C4.39997 6.81333 4.18997 7.95667 4.18997 9.17L2.50997 7.98H7.54997V15.12H0.409973ZM12.17 15.12V10.15C12.17 8.23666 12.4733 6.46333 13.08 4.83C13.7333 3.19667 14.83 1.58667 16.37 0L19.1 2.03C17.8866 3.38333 17.0466 4.62 16.58 5.74C16.16 6.81333 15.95 7.95667 15.95 9.17L14.27 7.98H19.31V15.12H12.17Z"
                    fill="#E0E0E0"
                  ></path>
                </svg>
                <span class="main-review-card-title">{{ review.title }}</span>
              </div>
              <div class="main-review-card-description">
                <span v-if="review.content && review.content.includes('editor_content_inserted')">{{
                  getPlainText(review.editor_content)
                }}</span>
                <span v-else>{{ review.content }}</span>
              </div>
              <div class="main-review-card-img">
                <img
                  :src="getFirstImageUrl(review.safeHtml)"
                  v-if="getFirstImageUrl(review.safeHtml)"
                  alt="Review Image"
                />
              </div>
              <div class="main-review-card-author">
                <span>{{ review.author }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="main-review-button-container">
          <router-link to="/review"><span>더보기</span></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ReviewArea",
  data: () => ({
    page: 1,
    pageCount: 0,
    limit: 5,
    reviews: [],
    defaultReviews: [
      {
        author: "김*영 회원님",
        title: "퓨처온 강의 후기",
        content:
          "알 것 같으면서도 모르고 모를 것 같으면서 더 모르는 해외 선물..\n 이왕 발들인거 퓨처온 강의를 토대로 열심히 공부해 볼게요.",
      },
      {
        author: "홍*준 회원님",
        title: "촛불같은 희망이 보여요",
        content:
          "알 것 같으면서도 모르고 모를 것 같으면서 더 모르는 해외 선물.. 이왕 발들인거 퓨처온 강의를 토대로 열심히 공부해 볼게요.",
      },
      {
        author: "김*영 회원님",
        title: "촛불같은 희망이 보여요",
        content:
          "알 것 같으면서도 모르고 모를 것 같으면서 더 모르는 해외 선물..\n 이왕 발들인거 퓨처온 강의를 토대로 열심히 공부해 볼게요.",
      },
      {
        author: "강*동 회원님",
        title: "퓨처온 강의 후기",
        content:
          "알 것 같으면서도 모르고 모를 것 같으면서 더 모르는 해외 선물..\n 이왕 발들인거 퓨처온 강의를 토대로 열심히 공부해 볼게요.",
      },
      {
        author: "이*준 회원님",
        title: "퓨처온 강의 후기",
        content:
          "알 것 같으면서도 모르고 모를 것 같으면서 더 모르는 해외 선물..\n 이왕 발들인거 퓨처온 강의를 토대로 열심히 공부해 볼게요.",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
    computedReviews() {
      return this.isMobile ? this.reviews.slice(0, 3) : this.reviews;
    },
  },
  mounted() {
    this.fetchReview();
  },
  methods: {
    formatName(name) {
      if (!name) return "익명 회원님";
      return `${name.charAt(0)}*${name.charAt(name.length - 1)} 회원님`;
    },
    async fetchReview() {
      const reviews = await this.$axios.get(`${process.env.VUE_APP_API_URL}/review/review_all`, {
        params: {
          page: this.page,
          pageSize: this.limit,
        },
      });

      console.log("review", reviews);

      this.reviews = reviews.data.items
        .filter((review) => review.status === 1 && review.order !== null)
        .sort((a, b) => a.order - b.order)
        .slice(0, 5)
        .map(async (review) => {
          const safeHtml = await this.loadSafeHtml(review.editor_content);
          return {
            author: this.formatName(review.user_name),
            title: review.title,
            content: review.content || "",
            editor_content: review.editor_content || "",
            safeHtml,
          };
        });

      console.log(this.reviews);
      this.reviews = await Promise.all(this.reviews);

      if (this.reviews.length === 0) {
        this.reviews = this.defaultReviews;
      } else if (this.reviews.length < this.limit) {
        this.reviews = this.reviews.concat(
          this.defaultReviews.slice(0, this.limit - this.reviews.length)
        );
      }
    },
    getPlainText(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      const text = div.textContent || div.innerText || "";
      return text.split("\n").slice(0, 2).join(" ") || " ";
    },
    getFirstImageUrl(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      const img = div.querySelector("img");
      return img ? img.src : "";
    },
    async loadSafeHtml(html) {
      if (html) {
        const safeHtml = await this.viewHtml(html);
        this.$nextTick(() => {
          const videos = document.querySelectorAll(".html-content iframe");
          const images = document.querySelectorAll(".html-content p > img");
          videos.forEach((video) => {
            video.style.width = "100%";
            video.style.height = "56.25vh";
          });
          images.forEach((img) => {
            img.style.width = "100%";
          });
        });
        return safeHtml;
      }
      return "";
    },
    async viewHtml(html) {
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/content/editor_view_user`,
          {
            html,
          }
        );
        return response.data.message === "Success"
          ? response.data.html
          : "데이터를 불러오는데 실패했습니다.";
      } catch (error) {
        console.error("Error fetching html:", error);
        return "데이터를 불러오는데 실패했습니다.";
      }
    },
  },
};
</script>
<style scoped>
.main-review-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  height: 100vh;
  max-height: 37.5rem;
  overflow: hidden;
  background: #212121;
  gap: 1.25rem;
}
.main-review-container.mobile {
  padding: 2.5rem 0;
  height: auto;
  max-height: 100%;
}

.main-review-wrapper1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  position: relative;
  z-index: 1;
}
.main-review-container.mobile .main-review-wrapper1 {
  height: auto;
  max-height: 100%;
}

.main-review-background {
  position: absolute;
  width: 135.625rem;
  height: 66.25rem;
  left: -45%;
  top: -12.5%;
  object-fit: cover;
  filter: brightness(0.2);
  z-index: 0;
}

.main-review-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  position: relative;
  z-index: 1;
}
.main-review-container.mobile .main-review-wrapper {
  padding: 0 1.25rem;
}

.main-review-header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
}

.main-review-header {
  display: flex;
  width: 100%;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.main-review-sub-header {
  display: flex;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.main-review-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 18.375rem;
}
.main-review-container.mobile .main-review-card-container {
  justify-content: space-between;
  align-items: center;
  height: auto;
  max-height: 100%;
}

.main-review-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 18.313rem;
  overflow: hidden;
  gap: 0.625rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
}
.main-review-container.mobile .main-review-card {
  height: auto;
  max-height: 100%;
  width: 11.5rem;
}

.main-review-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.main-review-container.mobile .main-review-card-content {
  height: 100%;
  width: 100%;
  padding: 0.625rem;
}

.main-review-card-text {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.main-review-container.mobile .main-review-card-text {
  height: 100%;
  width: 100%;
  gap: 0.3125rem;
}

.main-review-card-title {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  color: #ff5252;
  width: 10.313rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 28px;
}
.main-review-container.mobile .main-review-card-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-review-card-description {
  display: -webkit-box;
  width: 11.5rem;
  height: 4.5rem;
  font-size: 1rem;
  text-align: left;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
.main-review-container.mobile .main-review-card-description {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.main-review-card-img {
  width: 11.5rem;
  height: 4.5rem;
}
.main-review-container.mobile .main-review-card-img {
  width: 100%;
  height: auto;
}
.main-review-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-review-card-author {
  display: flex;
  align-self: flex-end;
  font-size: 0.75rem;
  text-align: right;
  color: #424242;
}
.main-review-container.mobile .main-review-card-author {
  font-size: 0.875rem;
  align-self: center;
}

.main-review-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11.75rem;
  height: 3.375rem;
  border-radius: 3.125rem;
  background: #4589ff;
  box-shadow: 0 0.625rem 0.625rem 0 rgba(69, 137, 255, 0.25);
  gap: 0.625rem;
  z-index: 1;
}
.main-review-button-container a,
.main-review-button-container a:visited {
  text-decoration: none;
  color: #fff;
}
.main-review-button-container span {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  text-decoration: none;
}
</style>
