import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Main from "../views/Main.vue";
import SignIn from "../views/SignIn.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: SignIn,
  },
  {
    path: "/login",
    name: "LogIn",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "RegisterView",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/register/Register.vue"),
  },
  {
    path: "/registerform",
    name: "RegisterForm",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/register/RegisterForm.vue"),
  },
  {
    path: "/welcome",
    name: "WelCome",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/register/Welcome.vue"),
  },
  {
    path: "/findaccount",
    name: "FindAccount",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/register/FindAccount.vue"),
  },
  {
    path: "/findsuccess",
    name: "FindSuccess",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/register/FindSuccess.vue"),
  },
  {
    path: "/mypage",
    component: () => import("@/views/mypage/MypageLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/mypage/MyInfo.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "mylecture",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/mypage/MyLecture.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "mysms",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/mypage/MySms.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "mycharge",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/mypage/MyCharge.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "myleave",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/mypage/MyLeave.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        children: [
          {
            path: "next",
            component: () =>
              import(/* webpackChunkName: "mypage" */ "@/views/mypage/MyLeaveNext.vue"),
            meta: {
              needAuth: false,
              needLogin: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/expert",
    component: () => import("@/views/expert/ExpertLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "/expert/schedule",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/expert/ManageSchedule.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/expert/managevod",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/expert/ManageVod.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/expert/managemember",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/expert/ManageMember.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/mafia",
    name: "ExpertIndividualMafia",
    component: () => import("@/views/expert/ExpertIndividualMafia.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    path: "/lineup",
    name: "LineupLayout",
    component: () => import("@/views/lineup/LineupLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
    children: [
      {
        path: "/lineupinfo",
        name: "LineupInfo",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/lineup/LineupInfo.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/lineupvod",
        name: "LineupVod",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/lineup/LineupVod.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/lineupreview",
        name: "LineupReview",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/lineup/LineupReview.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/lineupjoin",
        name: "LineupJoin",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/lineup/LineupJoin.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/lineupwrite",
        name: "LineupReviewWrite",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/lineup/LineupReviewWrite.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
    ],
  },
  {
    path: "/lineup/:id",
    name: "LineupLayoutWithId",
    component: () => import("@/views/lineup/LineupLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/futures/futuresuse",
    component: () => import(/* webpackChunkName: "mypage" */ "@/views/futures/FuturesUse.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    path: "/futures/futureslive",
    component: () => import(/* webpackChunkName: "mypage" */ "@/views/futures/FuturesLive.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    name: "FuturesVod",
    path: "/futures/futuresVod",
    component: () => import(/* webpackChunkName: "mypage" */ "@/views/futures/FuturesVod.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/expertkakao",
    name: "ExpertKakao",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/ExpertKakao.vue"),
  },
  {
    path: "/academy",
    name: "AcademyLayout",
    component: () => import("@/views/academy/AcademyLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    name: "AcademyCurriculum",
    path: "/curriculum",
    component: () =>
      import(/* webpackChunkName: "mypage" */ "@/views/academy/AcademyCurriculum.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
    children: [
      {
        name: "AcademyList",
        path: "lecturelist",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/academy/AcademyList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        name: "AcademyReview",
        path: "review",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/academy/AcademyReview.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    name: "YoutubeList",
    path: "/youtube",
    component: () => import("@/views/youtube/YoutubeList.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    name: "BasicList",
    path: "/basic",
    component: () => import("@/views/basic/BasicList.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    path: "/review",
    redirect: "/review/list",
    component: () => import("@/views/review/ReviewLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "profit",
        name: "ReviewProfit",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/review/ReviewProfit.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "list",
        name: "ReviewList",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/review/ReviewList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      // {
      //   path: "silmido",
      //   component: () =>
      //     import(/* webpackChunkName: "mypage" */ "@/views/review/ReviewSpecial.vue"),
      //   meta: {
      //     needAuth: false,
      //     needLogin: true,
      //   },
      // },
      {
        path: "view",
        name: "ReviewView",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/review/ReviewView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "write",
        name: "ReviewWrite",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/review/ReviewWrite.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/event",
    name: "EventLayout",
    redirect: "/event/now",
    component: () => import("@/views/event/EventLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "now",
        name: "EventNow",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/event/EventNow.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        children: [
          {
            path: "view",
            name: "EventView",
            component: () => import(/* webpackChunkName: "mypage" */ "@/views/event/EventView.vue"),
            meta: {
              needAuth: false,
              needLogin: true,
            },
            props: (route) => ({
              status: Number(route.query.status),
              currentIndex: Number(route.query.currentIndex),
              page: Number(route.query.page),
              pageCount: Number(route.query.pageCount),
              limit: Number(route.query.limit),
              viewTitle: route.query.viewTitle,
            }),
          },
        ],
      },
      {
        path: "expired",
        name: "EventExpired",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/event/EventExpired.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        children: [
          {
            path: "view",
            name: "EventView",
            component: () => import(/* webpackChunkName: "mypage" */ "@/views/event/EventView.vue"),
            meta: {
              needAuth: false,
              needLogin: true,
            },
            props: (route) => ({
              status: Number(route.query.status),
              currentIndex: Number(route.query.currentIndex),
              page: Number(route.query.page),
              pageCount: Number(route.query.pageCount),
              limit: Number(route.query.limit),
              viewTitle: route.query.viewTitle,
            }),
          },
        ],
      },
      {
        path: "winner",
        name: "EventWinner",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/event/EventWinner.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        children: [
          {
            path: "view",
            name: "EventWinnerView",
            component: () =>
              import(/* webpackChunkName: "mypage" */ "@/views/event/EventWinnerView.vue"),
            meta: {
              needAuth: false,
              needLogin: true,
            },
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/notice",
    redirect: "/notice/list",
    component: () => import("@/views/notice/NoticeLayout.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "/notice/list",
        name: "NoticeList",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/notice/NoticeList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/notice/qna",
        name: "NoticeQna",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/notice/NoticeQna.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        name: "NoticeView",
        path: "/notice/view",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/notice/NoticeView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: (route) => ({
          currentIndex: Number(route.query.currentIndex),
          page: Number(route.query.page),
          pageCount: Number(route.query.pageCount),
          limit: Number(route.query.limit),
        }),
      },
    ],
  },
  {
    path: "/invest",
    component: () => import("@/views/invest/InvestLayout.vue"),
    redirect: "/invest/calendar",
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "calendar",
        name: "InvestCalendar",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/invest/InvestCalendar.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "daily",
        name: "InvestDaily",
        component: () => import(/* webpackChunkName: "mypage" */ "@/views/invest/InvestDaily.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "analysis",
        name: "InvestAnalysis",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/invest/InvestAnalysis.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "view",
        component: () =>
          import(/* webpackChunkName: "mypage" */ "@/views/invest/InvestDailyView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/terms/:activeBtn",
    name: "TermsView",
    component: () => import("@/views/Terms.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
  },
  {
    name: "VipJoin",
    path: "/vipjoin",
    component: () => import("@/views/vip/VipJoin.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
  },
  {
    name: "AmountPaid",
    path: "/amount",
    component: () => import("@/views/amount/AmountPaid.vue"),
    meta: {
      needAuth: false,
      needLogin: true,
    },
    props: true,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

router.beforeEach(async (to, from, next) => {
  console.log(to, from);
  // 허용되지 않은 경로 블럭
  // if (!to.matched.length) {
  //   store.dispatch('dialog/alert', ['error', '잘못된 접근입니다.']);
  //   router.push('/');
  //   next(false);
  // }
  if (to.name === "VipJoin") {
    store.commit("vipBanner/SET_VIP_BANNER_VISIBLE", false);
  } else {
    store.commit("vipBanner/SET_VIP_BANNER_VISIBLE", true);
  }
  next();
});

router.afterEach(async (to) => {
  console.log(to);
  store.dispatch("mobile/updateMobileContext");
});

router.checkAuth = async () => {
  const to = router.currentRoute;
  const connected = store.getters["auth/connected"];
  const initialized = store.getters["auth/initialized"];
  const verified = store.getters["auth/verified"];
  const userInfo = store.getters["auth/userInfo"];
  const companyInfo = store.getters["auth/companyInfo"];
  const permission = userInfo ? userInfo.permission : null;

  if (connected && initialized) {
    if (!verified && to.meta.needLogin) {
      await router.push("/signin");
    } else if (verified && to.path === "/signin" && to.path !== "/") {
      await router.push("/");
    } else if (verified && to.meta.needAuth) {
      if (to.meta.category !== "boards") {
        const authBool =
          to.meta.func === undefined
            ? permission[to.meta.category].menu[to.meta.menu].bool
            : permission[to.meta.category].menu[to.meta.menu].func[to.meta.func].bool;
        if (authBool === false) {
          store.dispatch("dialog/alert", ["error", "접근 권한이 없습니다."], false);
          router.back();
        }
      }
    }
  } else if (companyInfo.code !== "") {
    localStorage.removeItem(`MANOadmin.${companyInfo.code}.authToken`);
    if (to.path !== "/signin" && to.meta.needLogin) {
      await router.push("/signin");
    }
  }
};
router.goBack = () => {
  router.back();
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

// 전역 참조 허용 함수
(() => {
  window.$router = {
    goBack: router.goBack,
  };
})();

export default router;
